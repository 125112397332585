import axios from "axios";
import store from "@/store"

const authApi = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_AUTH_BASE_URL,
})

authApi.interceptors.request.use(
  config => {
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'

    if(store.getters['getToken']) {
      config.headers['Authorization'] = `Bearer ${store.getters['getToken']}`;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default authApi