import { createStore } from "vuex";

import authModule from "./auth"
import nodeModule from "./node"

const store = createStore({
    modules: {
        authModule,
        nodeModule
    }
})

export default store
