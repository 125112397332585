import { createApp } from "vue"

import "@fortawesome/fontawesome-free/css/all.min.css";
import Notifications from "vue3-vt-notifications"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./styles/styles.css"

createApp( App )
    .use( router )
    .use( store )
    .use( Notifications )
    .mount( "#app" )
