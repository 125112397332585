export default {
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth-layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register-view" */ '@/modules/auth/views/RegisterView.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login-view" */ '@/modules/auth/views/LoginView.vue'),
        },
        {
            path: '/callback',
            name: 'callback',
            component: () => import(/* webpackChunkName: "authenticating-view" */ '@/modules/auth/views/Authenticating.vue'),
        },
    ]
}