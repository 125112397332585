export default {
    name: "node",
    component: () => import(/* webpackChunkName: "dashboard-layout" */ '@/modules/dashboard/layouts/DashboardLayout.vue'),
    children: [
        {
            path: "/detail/:slug",
            name: "node-detail",
            component: () => import(/* webpackChunkName: "detail-view" */'@/modules/dashboard/modules/detail/views/DetailView.vue'),
        }
    ]
}