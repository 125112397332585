export default {
    component: () => import(/* webpackChunkName: "dashboard-layout" */ '@/modules/dashboard/layouts/DashboardLayout.vue'),
    children: [
        {
            path: '',
            name: 'profile',
            component: () => import(/* webpackChunkName: "profile-view" */ '@/modules/profile/views/ProfileView.vue'),
        }
    ]
}
