import authApi from '@/api/authApi';

export const setToken = (context, payload) => {
    localStorage.setItem('token', payload)
    context.commit('updateToken', payload)
}

export const revokeToken = (context) => {
    localStorage.removeItem('token');
    context.commit('updateToken', 0);
}

export const checkAuth = async(context) => {
    const { status, statusText } = await authApi.get('api/favorites')
    .catch( error => error.response )

    if (status !== 200) {
        localStorage.removeItem('token');
        context.commit('updateToken', 0);

        return {
            ok: false,
            message: statusText
        }
    }

    return {
        ok: true,
        message: statusText
    }
}