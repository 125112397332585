import { createRouter, createWebHistory } from "vue-router"
import AuthRouter from "@/modules/auth/router/index"
import DashboardRouter from "@/modules/dashboard/router/index"
import DetailRouter from "@/modules/dashboard/modules/detail/router/index"
import ProfileRouter from "@/modules/profile/router/index"
import LandingRouter from "@/modules/landing/router/index"
import authGuard from '@/router/authGuard'

const routes = [
  {
    path: "/",
    ...LandingRouter,
  },
  {
    path: "/auth",
    ...AuthRouter,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/dashboard",
    beforeEnter: [ authGuard ],
    ...DashboardRouter,
  },
  {
    path: "/profile",
    ...ProfileRouter,
    beforeEnter: [ authGuard ],
  },
  {
    path: "/detail",
    ...DetailRouter,
    beforeEnter: [ authGuard ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router