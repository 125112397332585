import store from "@/store"

const isAuthenticatedGuard = async(to, from, next) => {

    const { ok, message } = await store.dispatch('checkAuth')

    if (ok) next()
    else next({name: 'login'})
}

export default isAuthenticatedGuard